import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import TimeOffPlannerNoteForm from '../lib/TimeOffPlannerNoteForm'

class TimeOffPlannerController extends Controller {
  static targets = [
    'yearForm',
    'dailyPaceInput',
  ]

  connect() {
    this.dailyPaceInputTarget.addEventListener('change', this.#updateDailyPace.bind(this))
  }

  disconnect() {
    this.noteForm?.removeEventListeners()
  }

  #updateDailyPace() {
    this.dailyPaceInputTarget.closest('form').submit()
  }

  updateYear(_event) {
    Rails.fire(this.yearFormTarget, 'submit')
  }

  showNoteForm(event) {
    event.stopPropagation()
    const selectedDayNode = event.target
    if (!this.noteForm) {
      this.noteForm = new TimeOffPlannerNoteForm(selectedDayNode)
    } else {
      this.noteForm.updateSelectedDayNode(selectedDayNode)
    }
    this.noteForm.show()
  }

  submitNoteForm(_event) {
    this.noteForm?.submit()
  }

  noteFormOnChange(event) {
    this.noteForm?.onInputChange()
  }
}

export default TimeOffPlannerController
