import { Controller } from '@hotwired/stimulus'

class SwitcherController extends Controller {
  static targets = ['switchLeft', 'switchRight']

  static values = {
    param: String,
    activeClass: { type: String, default: 'switcher__switch--active' }
  }

  connect() {
    this.switchLeftTarget.classList.toggle(this.activeClassValue)

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.get(this.paramValue) === 'true') {
        this.switchRightTarget.classList.add(this.activeClassValue)
        this.switchLeftTarget.classList.remove(this.activeClassValue)
      }
    }
  }

  toggle() {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)
      const currentValue = searchParams.get(this.paramValue)
      const newValue = currentValue === 'true' ? 'false' : 'true'
      searchParams.set(this.paramValue, newValue)
      window.location.search = searchParams.toString()
    }
  }
}

export default SwitcherController
